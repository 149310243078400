.background {
    height: 100%;
}

.content {
    display: flex;
    justify-content: center;
    align-items: start;
    min-height: 90vh;
    background: linear-gradient(to bottom, #92b3fc, #53a1fc);
}

.feedback-form {
    max-width: 1000px;
    width: 100%;
    background: #fff;
    padding: 25px 25px 30px;
    border-radius:8px;
    box-shadow: 0 0 10px rgba(101, 213, 247, 0.75);
    color: #333;
    margin: 60px 25px;
}

.feedback-form h2 {
    font-size: 40px;
    text-align: center;
}

.feedback-form label {
    font-weight: bold;
    color:rgba(10, 10, 10, 0.75)
}

.feedback-form .label-alignment {
    align-self: start;
    margin-top: 2px;
}

.feedback-form select option {
    color: #000000;
}

.feedback-form .input-box {
    width: 100%;
    margin-top: 20px;
}

.input-box .field {
    width: 100%;
    height: 50px;
    background: transparent;
    border: 2px solid #ddd;
    outline: none;
    border-radius: 6px;
    padding: 5px 15px;
    font-size: 16px;
    color: #333;
    margin-top: 8px;
    font-weight: 525;
    font-family: 'Arial', sans-serif;
}

.input-box .field:hover {
    border:2px solid #3490fa;
}

.input-box .field:focus {
    border:2px solid #3490fa;
    box-shadow: 0 0 8px rgba(52, 144, 250, 0.6); /* Adds shadow effect */
}

.input-box .field.message {
    padding: 15px;
    flex-grow: 1;
    height: 200px;
    resize: none;
}

.feedback-form .attachment-button {
    width: 200px;
    height: 30px;
    border: none;
    outline: none;
    padding: 0px;
    padding-bottom: 2px;
    display: flex;
    border-radius: 6px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 42.5px;
    font-weight: 525;
    font-size: 16px;
    color:rgba(10, 10, 10, 0.75);
    background: linear-gradient(180deg, #f0f0f0, #d9d9d9, #b0b0b0);
}

.feedback-form .attachment-button:hover {
    box-shadow: 0 4px 10px rgba(52,144,250, 0.25);
    outline: 2px solid #3490fa;
}

.feedback-form .submit-button {
    width: 100%;
    height: 55px;
    background: #72b2fc;
    border: none;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, -1);
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    font-weight: 525;
    margin-top: 25px;
    transition: .5s
}

.feedback-form .submit-button:hover {
    background: #3490fa;
}

.feedback-form .submit-button:focus {
    background: #3490fa;
    outline: none;
}

.feedback-form .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.responseField {
    width: 200px;
    height: 30px;
    background: transparent;
    border: 2px solid #ddd;
    outline: none;
    border-radius: 6px;
    padding: 0px 0px;
    padding-left: 75px;
    font-size: 16px; 
    font-weight: 525;   
    color: #333;
    margin-bottom: 10px;
}

.responseField:hover {
    border:2px solid #3490fa;
}

.responseField:focus {
    color:#3490fa;
    border:2px solid #3490fa;
    box-shadow: 0 0 8px rgba(52, 144, 250, 0.6); /* Adds shadow effect */
}

@media (max-width: 768px){
    .feedback-form .container {
        display: block;
    }
}